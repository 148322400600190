<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="3">
            <h4>CUATRECASAS</h4>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <otherstatistics :data="statistics_data1_km" :isPageCat="'KM'" />
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-right">
                <v-btn
                  color="primary"
                  dark
                  @click="$refs.Kilometers.onItemEdit()"
                >
                  Editar
                </v-btn>
                <v-btn
                  color="warning"
                  dark
                  class="ml-2"
                  @click="$refs.Kilometers.downloadExcel()"
                >
                  <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
                  Export
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12" sm="12" md="12">
            <v-row>
              <v-col cols="12" sm="4" md="4" lg="4" class="d-flex align-center">
                <span
                  class="mr-4 p-2 bg-primary text-white text-body-1"
                  color="primary"
                  >CONTACTO</span
                >
                <span class="text-body-1">Javier Fernández</span>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4" class="d-flex align-center">
                <span
                  class="mr-4 p-2 bg-primary text-white text-body-1"
                  color="primary"
                  >MAIL</span
                >
                <span class="text-body-1">barcelona@cuatrecasas.com</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4" lg="4" class="d-flex align-center">
                <span
                  class="mr-4 p-2 bg-primary text-white text-body-1"
                  color="primary"
                  >COMERCIAL</span
                >
                <span class="text-body-1">Ignacio</span>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4" class="d-flex align-center">
                <span
                  class="mr-4 p-2 bg-primary text-white text-body-1"
                  color="primary"
                  >TELEFONO</span
                >
                <span class="text-body-1">+34 932 905 500 </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4" lg="4" class="d-flex align-center">
                <span
                  class="mr-4 p-2 bg-primary text-white text-body-1"
                  color="primary"
                  >DIRECCION</span
                >
                <span class="text-body-1"
                  >Av. Diagonal, 191, 08018 Barcelona</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import Kilometers from "./kilometers";
import Otherexpenses from "./otherexpenses";
import otherstatistics from "./otherstatistics";

export default {
  name: "approval",
  props: ["isteam"],
  components: {
    Kilometers,
    Otherexpenses,
    otherstatistics
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isSPV", "isGPV", "user"]),
    ...mapGetters("expenses", [
      "statistics_data1_km",
      "statistics_data1_other_expenses"
    ])
  },
  methods: {
    init() {}
  },
  mounted() {
    this.$store.dispatch("auth/getGlobalState");
    this.init();
  }
};
</script>

<style></style>
